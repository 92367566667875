import React, { useState, useMemo, useCallback } from 'react'
import { useRouter } from 'next/router'
import LazyLoad from 'react-lazyload'
import getConfig from 'next/config'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from 'src/components/pages/Home/HomeHeroBanner/HeroBannerContent/index.module.scss'
import SvgIcon from 'src/components/shared/SvgIcon'
import Img from 'src/components/shared/Img'
import Slider from 'src/components/shared/Slider'
import PhoneNumber from 'src/components/shared/PhoneNumber'
import TrustpilotItem from 'src/components/shared/TrusPilotItem'
import { useAnalytics } from 'src/common/analytics/index.jsx'
import { useLeadId } from 'src/common/lead-id'
import { getCookies } from 'src/common/helpers'
import { useNavigateToApply } from 'src/common/hooks/useNavigateToApply'

function HeroBannerFooter({
  content,
  headerInfo,
  isDefaultLang,
  shortView,
  isSimpleView = false,
  className,
}) {
  const { sliderTitle, buttonText, phoneSpeakText, number } = content
  const { track, utm_source } = useAnalytics()
  const { query: baseQuery } = useRouter()
  const cookieLeadId = useLeadId()
  const cookies = getCookies()
  const { APPLY_FDR_URL } = getConfig()?.publicRuntimeConfig
  const APPLY_URL = isDefaultLang
    ? `${APPLY_FDR_URL}/home/`
    : `${APPLY_FDR_URL}/espanol/`
  const [estimatedDebt, setDebt] = useState(25000)
  const navigateToApply = useNavigateToApply({
    utm_source,
    estimated_debt: estimatedDebt,
  })

  let query = useMemo(() => {
    const urlParams = {
      from: 'fdr',
      utm_source,
      ...baseQuery,
      estimated_debt: estimatedDebt,
      leadId: cookieLeadId,
      visit_id: cookies?.eh_visit_id || null,
    }

    for (let key in urlParams) {
      if (!urlParams[key]) {
        delete urlParams[key]
      }
    }

    const q = new URLSearchParams(urlParams)

    return q.toString()
  }, [baseQuery, cookieLeadId, cookies?.eh_visit_id, estimatedDebt, utm_source])
  const handleSliderChange = useCallback((event) => {
    const { value } = event.target
    setDebt(value)
  }, [])
  const handleSliderClick = useCallback(
    (e) => {
      e.preventDefault()

      const data = {
        nav_link_section: 'Page',
        page_section: 'sliderTitle',
        click_type: 'Button Click',
        click_id: buttonText,
        click_text: `FDR-Web | ${buttonText}`,
        form_ss_amount: +estimatedDebt,
        track_event: 'button_click',
      }

      if (typeof track === 'function') {
        track(data, {}, 'button_click')
      }

      navigateToApply()
    },
    [buttonText, estimatedDebt, track, navigateToApply]
  )

  const handleSliderRelease = useCallback(
    (e) => {
      const data = {
        nav_link_section: 'Page',
        page_section: 'sliderTitle',
        click_type: 'Button Click',
        click_id: 'slider release',
        click_text: `$${e.target.value}`,
        form_ss_amount: +e.target.value,
        track_event: 'button_click',
      }
      if (typeof track === 'function') {
        track(data, {}, 'button_click')
      }
    },
    [track]
  )

  return (
    <div
      data-testid="hero-banner-c"
      className={classNames(
        'flex flex-col items-center justify-center px-8 py-4 lg:pb-20',
        className,
        isSimpleView ? 'bg-blue-135' : 'bg-gray-25'
      )}
    >
      <h1
        className={classNames(
          'px-8 pb-2 pt-5 text-center text-heading-3 font-bold leading-[31.2px] text-black-font sm:text-36 sm:font-[500] lg:pt-10'
        )}
      >
        {headerInfo?.title}
      </h1>
      <div className="flex w-full flex-col justify-start lg:items-center">
        <div className="my-3 flex flex-col justify-center">
          {headerInfo?.list?.map((text, index) => (
            <div
              key={`hero-item-${index}`}
              className={`flex items-center py-1 ${
                isSimpleView ? 'ml-10' : ''
              }`}
            >
              <Img
                dataTestId="check-hero-item"
                alt="checkIcon"
                src="/next-assets/icons/check-green.svg"
                className="h-[18px] w-[15px]"
                styles={{ position: 'relative' }}
              />
              <span
                className={`sm:text-bold ml-2 ${
                  isSimpleView
                    ? 'sm:text-heading-2.5 text-black-font'
                    : 'sm:text-heading-3.5'
                } text-15`}
              >
                {text}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div
        className={`0 my-4 flex w-full flex-col items-center bg-white py-4 text-center shadow-sm lg:mb-2 lg:w-[563px] ${
          shortView ? 'rounded-md' : ''
        }`}
      >
        <h2
          className={`text-2xl font-semibold leading-none ${
            shortView ? 'text-black-base' : 'text-blue-610'
          } `}
        >
          {sliderTitle}
        </h2>
        <LazyLoad offset={145} className="w-full px-14">
          <Slider
            valueClassName={`my-3 text-lg ${
              shortView ? 'text-blue-610 font-bold' : 'text-gray-900'
            } block`}
            containerClassName="mx-auto mb-10"
            sliderClassName={styles.slider}
            showMinMaxLabels
            onChange={handleSliderChange}
            value={+estimatedDebt}
            name="estimateSlider"
            step={1000}
            min={1000}
            max={100000}
            unit="$"
            onSliderRelease={handleSliderRelease}
          />
        </LazyLoad>
        {shortView && (
          <div className="my-5 w-full bg-green-50 p-5">
            <span>
              You could free up{' '}
              <b className="font-extrabold text-[#046240]">$258/mo</b> 🎉
            </span>
          </div>
        )}
        <a
          className="btn-white inline w-3/4 bg-red-500 p-2 text-lg text-white"
          href={`${APPLY_URL}?${query}`}
          onClick={handleSliderClick}
          rel="noreferrer"
          id="apply-url"
        >
          <div className="flex items-center justify-center">
            {buttonText}
            <div className="ml-1 flex h-5 w-5 items-center justify-center rounded-full bg-white text-red-500">
              <SvgIcon
                name="keyboardArrowRight"
                className="h-5 w-5 fill-red-500"
              />
            </div>
          </div>
        </a>
        <p className="mt-3 whitespace-pre-wrap px-3 text-sm text-blue-700 lg:text-xs">
          <PhoneNumber
            href={`tel:${number}`}
            text={number}
            speakText={phoneSpeakText}
            className="block whitespace-nowrap text-xl font-bold text-blue-700 lg:ml-2 lg:inline"
            hasExperiment={true}
            isTrack={true}
            nav_link_section="Slider"
          />
        </p>
        {!shortView && (
          <TrustpilotItem
            starClassName="w-5.5 h-5.5"
            trustScoreClassName="text-base"
            trustpilotClassName="text-base"
            trustpilotRatingClassName="text-base"
            starsGroupClassName="w-20 h-5 mx-2.5"
            className="mt-5"
            starsGroupImgClassName="pt-1"
            isHome={true}
          />
        )}
      </div>
    </div>
  )
}

HeroBannerFooter.propTypes = {
  isDefaultLang: PropTypes.bool,
  headerInfo: PropTypes.object,
  className: PropTypes.string,
  isSimpleView: PropTypes.bool,
  sliderProps: PropTypes.object,
}

export default HeroBannerFooter
